// Bootstrap JS
const bootstrap = require('bootstrap');
import ScrollToTop from '../../_includes/partials/components/scrollToTop/scrollToTop';
import Contact from '../../_includes/partials/components/contact/contact';

document.addEventListener("DOMContentLoaded", function(){

  // Disable right click
  // document.addEventListener('contextmenu', event => event.preventDefault());

  // Typewriter effect on main title
  (function () {
    var titleNode = document.querySelector('#home-title-container .title-text');
    var caretNode = document.querySelector('#home-title-container .title-caret');
    var subtitleNode = document.querySelector('#home-subtitle-container');

    // Retrieve translation
    var titleText = titleNode.dataset.title;

    setTimeout(function () {
      var i = 0;
      var handle = setInterval(function () {
        if (i >= titleText.length) {
          clearInterval(handle);
          caretNode.style.display = 'none';
          subtitleNode.style.opacity = '1';
          return;
        }
        titleNode.textContent += titleText.charAt(i);
        i++;
      }, 120);
    }, 1000);
  })();

    // Hide on scroll
    let el_autohide = document.querySelector('.autohide');    
    
    if(el_autohide){
        let last_scroll_top = 0;
        window.addEventListener('scroll', function() {
            let scroll_top = window.scrollY;
            if(scroll_top < last_scroll_top) {
                el_autohide.classList.remove('scrolled-down');
                el_autohide.classList.add('scrolled-up');                
            }
            else {
                el_autohide.classList.remove('scrolled-up');
                el_autohide.classList.add('scrolled-down');                
            }
            last_scroll_top = scroll_top;
        }); 
        // window.addEventListener
    }

    // GDPR
    if (!window.gdprDisplayed) {
        const gdprModalElt = document.getElementById("gdpr-modal");
        const gdprModal = new bootstrap.Modal(gdprModalElt);
        gdprModal.show();
    }

    window.setGdprConsent = (acceptAll) => {
        if (!window.localStorage) {
            return;
        }
        const cookiesStatsElt = document.querySelector("#gdpr-cookies-stats");
        if (!cookiesStatsElt) {
            return;
        }
        const isAccepted = acceptAll || cookiesStatsElt.checked;
        window.localStorage.setItem("aspotGdprConsent", isAccepted)
        window.location.reload();
    };
});